import Component from 'gia/Component';

export default class loader extends Component {
	constructor(element) {
		super(element);
	}

	mount() {
		$(window).on('load', function () {
			setTimeout(function () {
				$('#loader').addClass('ready');
			}, 1000);

			$('#enter').click(function () {
				setTimeout(function () {
					$('#loader').removeClass('loading');
				}, 500);
				setTimeout(function () {
					$('#loader').remove();
				}, 1500);
				setTimeout(function () {
					$('html').addClass('ready');
				}, 2000);
			});
		});
	}
}

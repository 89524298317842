import Lenis from '@studio-freight/lenis';
//import { ScrollSnap } from '../../js/vendor/scroll-snap';

import Component from 'gia/Component';

// Extend the GIA component
export default class scroll extends Component {
	constructor(element) {
		super(element);
	}

	// mount is equivalent to $('document').ready and will only fire if your component is on the page
	// add event listeners here
	mount() {

		const lenis = new Lenis({
			duration: 1.5,
			easing: (t) => (t === 1 ? 1 : 1 - Math.pow(2, -10 * t)), // https://easings.net
			direction: 'vertical',
			smooth: true,
			smoothTouch: false,
			touchMultiplier: 2,
		})

		window.lenis = lenis;

		function raf() {
			lenis.raf();
			requestAnimationFrame(raf);
		}

		requestAnimationFrame(raf);

		document.querySelectorAll('a[href^="#"]').forEach(anchor => {
			anchor.addEventListener('click', function (e) {
				e.preventDefault();
				lenis.scrollTo(this.getAttribute('href'))
			});
		})

	}
}

// import GIA dependancy
import Component from 'gia/Component';

// Extend the GIA component
export default class modal extends Component {
	constructor(element) {
		super(element);
	}

	// mount is equivalent to $('document').ready and will only fire if your component is on the page
	// add event listeners here
	mount() {
		const triggers = document.getElementsByClassName('c-services_item');
		const triggerArray = Array.from(triggers).entries();
		const modals = document.getElementsByClassName('c-modal');
		const closeButtons = document.getElementsByClassName('close');

		for (let [index, trigger] of triggerArray) {
			const toggleModal = () => {
				modals[index].classList.toggle('active');
				document.body.classList.toggle('locked');
			};
			trigger.addEventListener('click', toggleModal);
			closeButtons[index].addEventListener('click', toggleModal);
		}
	}
}

import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import SplitText from '../vendor/SplitText.min.js';

import Component from 'gia/Component';

export default class inview extends Component {
	constructor(element) {
		super(element);
	}

	mount() {
		gsap.registerPlugin(ScrollTrigger);

		const visible = gsap.utils.toArray('[data-scroll]');

		visible.forEach((visible) => {
			gsap.from(visible, {
				scrollTrigger: {
					start: 'top bottom',
					end: 'bottom top',
					trigger: visible,
					toggleClass: 'in-view',
				},
			});
		});

		gsap.utils.toArray('.lerp').forEach((section) => {
			gsap.to(section, {
				// y: -200,
				yPercent: -20,

				ease: 'power2',
				scrollTrigger: {
					trigger: section,
					scrub: true,
				},
			});
		});

		var header = document.querySelector('.l-header');

		// all good - it works
		const sections = gsap.utils.toArray('[data-bg="_white"]');
		sections.forEach((section) => {
			ScrollTrigger.create({
				trigger: section,
				start: 'top top-=-80',
				end: 'bottom top-=-80',
				toggleClass: {
					targets: header,
					className: 'invert',
				},
				//markers: 'true'
			});
		});


		if (document.querySelector('[data-speed]')) {
			// apply parallax effect to any element with a data-speed attribute
			gsap.utils.toArray('[data-speed]').forEach((el) => {
				gsap.to(el, {
					y: function () {
						return (
							(1 - parseFloat(el.getAttribute('data-speed'))) *
							(ScrollTrigger.maxScroll(window) - (this.scrollTrigger ? this.scrollTrigger.start : 0))
						);
					},
					ease: 'none',
					scrollTrigger: {
						trigger: '.l-hero-home	',
						start: 'top',
						end: 'bottom',
						scrub: 1,
					},
				});
			});
		}

		function setupSplits() {
			const targets = gsap.utils.toArray('.c-loader > p, .l-big-heading h2');
			targets.forEach((target) => {
				let SplitClient = new SplitText(target, {
					type: 'lines',
					linesClass: 'line',
					reduceWhiteSpace: false,
				});
				let chars = SplitClient.chars; //an array of all the divs that wrap each character
			});
		}

		setupSplits();

		// let sections = gsap.utils.toArray('.c-horizontal-block')

		// let scrollTween = gsap.to(sections, {
		//   xPercent: -100 * (sections.length - 1),
		//   ease: 'none', // <-- IMPORTANT!
		//   scrollTrigger: {
		//     trigger: '.c-horizontal-blocks',
		//     pin: true,
		//     scrub: 0.1,
		//     //snap: directionalSnap(1 / (sections.length - 1)),
		//     start: 'center center',
		//     end: '+=2000',
		//   },
		// })

		if ($('._full').length > 0) {
			$('._full').parent('section').addClass('_pb');
		}
	}
}

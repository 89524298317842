import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import Glide from '@glidejs/glide';
import Component from 'gia/Component';

export default class hero extends Component {
	constructor(element) {
		super(element);
	}

	mount() {


		// new Glide('.glide', {
		// 	type: 'carousel',
		// 	startAt: 0,
		// 	perView: 3
		// })


		let revealContainers = document.querySelectorAll(".reveal");

		revealContainers.forEach((container) => {
			let image = container.querySelector("img");
			let tl = gsap.timeline({
				scrollTrigger: {
					trigger: container,
					toggleActions: "restart none none reset"
				}
			});

			tl.set(container, { autoAlpha: 1 });
			tl.from(container, 1.5, {
				xPercent: -100,
				ease: Power2.out
			});
			tl.from(image, 1.5, {
				xPercent: 100,
				scale: 1.3,
				delay: -1.5,
				ease: Power2.out
			});
		});





		new Glide('.glide', {
			type: 'carousel',
			startAt: 0,
			perView: 1,
			autoplay: 1000,
			hoverpause: false,
		}).mount()

		var scroll_pos = 0;
		$(document).scroll(function () {
			scroll_pos = $(this).scrollTop();
			if (scroll_pos > 200) {
				$('body.home .l-hero-home').addClass('dark');
				$('body.home .l-header').removeClass('dark');

			} else {
				$('body.home .l-hero-home').removeClass('dark');
				$('body.home .l-header').addClass('dark');

			}
		});
	}
}

import Component from 'gia/Component';

export default class cursor extends Component {
	constructor(element) {
		super(element);
	}

	mount() {
		var cursor = $('.c-cursor');

		$('.c-team_item')
			.mouseover(function () {
				$(cursor).addClass('active full').text('DRAG');
				$('html, *').css('cursor', 'none');
			})
			.mouseleave(function () {
				$(cursor).removeClass('active full').text('');
				$('html, *').css('cursor', 'auto');
			});

		$('.c-services_item')
			.mouseover(function () {
				$(cursor).addClass('active full').text('EXPAND');
				$('html, *').css('cursor', 'none');
			})
			.mouseleave(function () {
				$(cursor).removeClass('active full').text('');
				$('html, *').css('cursor', 'auto');
			});

		$('.l-header a')
			.mouseover(function () {
				$(cursor).addClass('active ring');
				$('html, *').css('cursor', 'none');
			})
			.mouseleave(function () {
				$(cursor).removeClass('active ring');
				$('html, *').css('cursor', 'auto');
			});




		$('.glide__arrow--left')
			.mouseover(function () {
				$(cursor).addClass('active').text('PREV ');
				$('html').css('cursor', 'none');
			})
			.mouseleave(function () {
				$(cursor).removeClass('active').text('');
				$('html').css('cursor', 'auto');
			});

		$('.glide__arrow--right')
			.mouseover(function () {
				$(cursor).addClass('active').text('NEXT');
				$('html').css('cursor', 'none');
			})
			.mouseleave(function () {
				$(cursor).removeClass('active').text('');
				$('html').css('cursor', 'auto');
			});

		$(window).mousemove(function (e) {
			cursor.css({
				top: e.clientY - cursor.height() / 2,
				left: e.clientX - cursor.width() / 2,
			});
		});
	}
}

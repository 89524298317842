import Swup from 'swup';
import DebugPlugin from '@swup/debug-plugin';
import SwupScrollPlugin from '@swup/scroll-plugin';
import SwupPreloadPlugin from '@swup/preload-plugin';
import SwupScriptsPlugin from '@swup/scripts-plugin';
import SwupBodyClassPlugin from '@swup/body-class-plugin';
import SwupFormsPlugin from '@swup/forms-plugin';
import SwupJsPlugin from '@swup/js-plugin';

import loadComponents from 'gia/loadComponents';
import components from './components';

// enable components
loadComponents(components);

//swup JSplugin options
const options = [
	{
		from: '(.*)',
		to: '(.*)',
		in: (next) => {
			window.scrollTo(0, 0);

			setTimeout(next, 1000);
		},
		out: (next) => {
			setTimeout(next, 1000);
		},
	},
];

// enable swup
const swup = new Swup({
	elements: ['#main'],
	cache: false,
	linkSelector:
		'a[href^="' +
		window.location.origin +
		'"]:not([data-no-swup]), a[href^="/"]:not([data-no-swup]), a[href^="./"]:not([data-no-swup]), a[href^="#"]:not([data-no-swup])',
	plugins: [
		//	new DebugPlugin(),
		// new SwupScrollPlugin({
		//     doScrollingRightAway: true,
		//     animateScroll: true,
		//     scrollFriction: 0.3,
		//     scrollAcceleration: 0.04,
		// }),
		// new SwupPreloadPlugin(),
		new SwupScriptsPlugin({
			// head: true,
			// body: true
		}),
		new SwupJsPlugin(options),
		// new SwupScrollPlugin({
		// 	doScrollingRightAway: false,
		// 	animateScroll: {
		// 		betweenPages: true,
		// 		samePageWithHash: true,
		// 		samePage: false,
		// 	},
		// 	scrollFriction: 0.3,
		// 	scrollAcceleration: 0.04,
		// 	getAnchorElement: null,
		// 	offset: () => document.querySelector('#header').offsetHeight,
		// 	scrollContainers: `[data-swup-scroll-container]`,
		// 	shouldResetScrollPosition: (htmlAnchorElement) => true,
		// }),
		new SwupFormsPlugin({
			formSelector: 'form',
		}),
		new SwupBodyClassPlugin(),
	],
});

swup.on('pageView', () => {
	console.log('New page loaded');

	var header = document.getElementById("header");
	header.classList.remove("invert");

	//document.documentElement.classList.remove('invert');
	// setTimeout(() => {
	// 	document.documentElement.classList.add('is-ready');
	// }, 1000);
});

// reload components for each container after transition
swup.on('contentReplaced', function () {
	document.querySelectorAll('[data-swup]').forEach(function (container) {
		loadComponents(components, container);
	});
});

// document.documentElement.classList.add('is-loaded');
// document.documentElement.classList.remove('is-loading');
// document.documentElement.classList.remove('is-ready');

// setTimeout(() => {
// 	document.documentElement.classList.add('is-loaded');
// }, 1000);

// setTimeout(() => {
// 	document.documentElement.classList.add('is-ready');
// }, 2000);

// // first animate in
// setTimeout(function () {
// 	document.documentElement.classList.remove('is-animating');
// }, 2000);

import basetheme from './components/basetheme';
import scroll from './components/c-scroll';
import inview from './components/c-inview';
import cursor from './components/c-cursor';
import hero from './components/c-hero';
import modal from './components/c-modal';
import nav from './components/c-nav';
import loader from './components/c-loader';
import drag from './components/c-drag';

const components = {
	basetheme,
	inview,
	cursor,
	hero,
	modal,
	nav,
	loader,
	scroll,
	drag
};

export default components;

import gsap from 'gsap';

// import GIA dependancy
import Component from 'gia/Component';

// Extend the GIA component
export default class drag extends Component {
	constructor(element) {
		super(element);
	}

	mount() {
		$('.c-team_item').on('click', 'a.flip', function (e) {
			$(this).parent().parent().parent().toggleClass('active');
			return false;
		});

		var draggableElements = document.getElementsByClassName('c-team_item');

		for (var i = 0; i < draggableElements.length; i++) {
			dragElement(draggableElements[i]);
		}

		function dragElement(elmnt) {
			var pos1 = 0,
				pos2 = 0,
				pos3 = 0,
				pos4 = 0;

			elmnt.onmousedown = dragMouseDown;

			function dragMouseDown(e) {
				e = e || window.event;
				pos3 = parseInt(e.clientX);
				pos4 = parseInt(e.clientY);
				document.onmouseup = closeDragElement;
				document.onmousemove = elementDrag;

				return false;
			}

			function elementDrag(e) {
				pos1 = pos3 - parseInt(e.clientX);
				pos2 = pos4 - parseInt(e.clientY);
				pos3 = parseInt(e.clientX);
				pos4 = parseInt(e.clientY);
				elmnt.style.top = elmnt.offsetTop - pos2 + 'px';
				console.log(elmnt.offsetTop);
				elmnt.style.left = elmnt.offsetLeft - pos1 + 'px';

				for (var i = 0; i < draggableElements.length; i++) {
					draggableElements[i].style.zIndex = '9';
				}
				elmnt.style.zIndex = '10';
			}

			function closeDragElement() {
				document.onmouseup = null;
				document.onmousemove = null;
			}
		}
	}
}
